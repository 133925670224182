import cx from 'classnames';
import crypto from 'crypto';
import { BarLoader } from 'react-spinners';
import React from 'react';

import store from '@stores';
import { FeedInfo } from '@stores/transit';
import { withStore } from '@stores/withStore';
import SelectGtfsFileDropdowns from '../CsvExplorePage/components/SelectGtfsFileDropdowns';

import 'react-tabs/style/react-tabs.css';
import 'react-virtualized/styles.css';

interface GtfsVisualizerProps {
  onFileChange(filename: string) : void;
  store: typeof store;
  feed: FeedInfo;
}

interface GtfsVisualizerState {
  isFullScreen: boolean;
  step: string;
}

class GtfsVisualizer extends React.Component<GtfsVisualizerProps, GtfsVisualizerState> {
  state = {
    isFullScreen: false,
    step: null,
  };

  toggleFullscreen = () => {
    this.setState({
      isFullScreen: !this.state.isFullScreen,
    });
  }

  onStepSelected = (step, filename) => {
    this.setState({
      step,
    });
  }

  renderLoading() {
    return (
      <div className="loader-wrapper">
        <BarLoader color="#30b566"/>
      </div>
    );
  }

  renderIFrame() {
    const { feed } = this.props;
    const { step } = this.state;

    if (!step) {
      return this.renderLoading();
    }

    return (
      <iframe
        width="100%"
        height="100%"
        src={`${process.env.UPDATE_STATIC_DATA_URL}/admin/transit_visualizer_${crypto.randomBytes(32).toString('hex')}/load_from/${feed.feed_code}/${step}`}
      />
    );
  }

  renderGtfsVisualizerControls() {
    const { isFullScreen } = this.state;

    return (
      <div className="csv-control-group" style={{ width: '100%' }}>
        <div className="csv-control">
          <div className="button open-file-button" onClick={this.toggleFullscreen}>
            <i className={`fa ${ isFullScreen ? 'fa-minus' : 'fa-arrows-alt' }`}/>
          </div>
        </div>
        <div className="csv-control">
          <SelectGtfsFileDropdowns
            onFileSelected={this.onStepSelected}
            hideFilenameDropdown={true}
          />
        </div>
      </div>
    );
  }

  render() {
    const { isFullScreen } = this.state;

    const wrapperClass = cx('csv-full-height', {
      'is-fullscreen': isFullScreen,
    });

    return (
      <div className={wrapperClass}>
        <div className="csv-control-wrapper">
          {this.renderGtfsVisualizerControls()}
        </div>
        <div style={{ width: '100%', height: '95vh' }}>
          {this.renderIFrame()}
        </div>
      </div>
    );
  }
}

export default withStore(GtfsVisualizer);
